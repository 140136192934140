import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import EditProfile from "./tabs/settings/EditProfile";
import EditProfessionalProfile from "./tabs/settings/EditProfessionalProfile";
import TermsOfService from "./tabs/settings/TermsOfService";
import PartnerServiceAgreement from "./tabs/settings/PartnerServiceAgreement";

//Helpers
import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from 'react-router-dom';

const ExpertSettingsDB = () => {
  const [selectedOption, setSelectedOption] = useState("Edit Profile");
  const [userData, setUserData] = useState({});

  const handleOption = (option) => {
    setSelectedOption(option);
  };

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const userID = JSON.parse(localStorage.getItem("profile")).UserId;
  const userEmail = JSON.parse(localStorage.getItem("profile")).Emailid;



  const navigate = useNavigate();
  const location = useLocation();

  // Check if user is logged in
  useEffect(() => {

    const isLoggedIn = isUserLoggedIn();

    if (!isLoggedIn) {
      // Redirect to "/login"
      //navigate('/login');
      window.location.href = `https://www${process.env.REACT_APP_CLIENT_DOMAIN}/`
    }
  }, [location, navigate]);

  //Professionals
  useEffect(() => {
    const payload = {
      user_id: userID,
      email: userEmail,
      screen: null,
    };

    const postData = async () => {
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/professionals/get`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();
        setUserData(postResponse?.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postData();
  }, []);

  //  console.log("userData", userData);

  return (
    <div className="my-3 SettingsDashboard">
      <div className="ExpertSettingsDashboard--Header">
        <h2>Settings</h2>
      </div>

      <div className="d-flex ExpertSettingsDashboard--Options  align-items-center mt-3">
        <div style={{ background: "#f0f0f0" }} className="d-flex rounded-pill p-1 gap-2 Options">
          <div
            className={`Option--Item rounded-pill  py-2 px-3 ${selectedOption == "Edit Profile" ? "SelectedItem" : ""
              }`}
            onClick={() => handleOption("Edit Profile")}
          >
            Edit Profile
          </div>
          <div
            className={`Option--Item rounded-pill  py-2 px-3 ${selectedOption == "Edit Professional Profile"
              ? "SelectedItem"
              : ""
              }`}
            onClick={() => handleOption("Edit Professional Profile")}
          >
            Edit Professional Profile
          </div>
          <div
            className={`Option--Item rounded-pill  py-2 px-3 ${selectedOption == "Terms of service" ? "SelectedItem" : ""
              }`}
            onClick={() => handleOption("Terms of service")}
          >
            Terms of service
          </div>
          <div
            className={`Option--Item rounded-pill  py-2 px-3 ${selectedOption == "Partner Service Agreement"
              ? "SelectedItem"
              : ""
              }`}
            onClick={() => handleOption("Partner Service Agreement")}
          >
            Partner Service Agreement
          </div>
        </div>
      </div>

      <div className="ExpertSettingsDashboard--Content">
        <h4>{selectedOption}</h4>
        {selectedOption == "Edit Profile" && (
          <EditProfile data={userData?.profile} />
        )}
        {selectedOption == "Edit Professional Profile" && (
          <EditProfessionalProfile data={userData?.professional} />
        )}
        {selectedOption == "Terms of service" && <TermsOfService />}
        {selectedOption == "Partner Service Agreement" && (
          <PartnerServiceAgreement />
        )}
      </div>
    </div>
  );
};

export default ExpertSettingsDB;
