import React from 'react';
import { TableRow, TableCell, IconButton, Typography, Button } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Visibility } from '@mui/icons-material';
// import EditIcon from '@mui/icons-material/Edit';
// import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';

const SessionNoteTabItem = ({ id, name, date, time, onView }) => {
    return (
        <TableRow hover className=''>
            {/* Session Name */}
            <TableCell>
                <Typography variant="body2" fontWeight="bold">
                    {name}
                </Typography>
            </TableCell>

            {/* Date of Last Update */}
            <TableCell>
                <Typography variant="body2">
                    Last update on {date}
                </Typography>
            </TableCell>

            {/* Time (if necessary) */}
            <TableCell>
                <Typography variant="body2">
                    {time}
                </Typography>
            </TableCell>

            {/* Actions */}
            <TableCell className='d-flex justify-content-start w-100' align="right">

                <Button
                    variant="text"
                    sx={{
                        borderRadius: '20px', // Rounded but not too much
                        bgcolor: '#f5f5f5',
                        color: '#414141',
                        fontSize: '14px', // 14px as in the default theme
                        textTransform: 'capitalize',
                        '&:hover': {
                            background: "#f5f5f5"
                        }
                    }} className="d-flex justify-content-center align-items-center gap-2 rounded-3 px-3 "
                    onClick={() => onView(id)}
                >
                    <Visibility />
                    View
                </Button>

                {/* Uncomment for additional actions */}
                {/* <IconButton color="primary">
          <EditIcon />
        </IconButton>

        <IconButton color="primary">
          <LocalPrintshopOutlinedIcon />
        </IconButton> */}
            </TableCell>
        </TableRow>
    );
};

export default SessionNoteTabItem;
