import React from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { saveAs } from "file-saver";

//Firebase
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  getStorage,
} from "firebase/storage";
import moment from "moment";
import { Button, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { Visibility } from "@mui/icons-material";
//import { storage } from "../../../../firebaseConfig";

const PrescriptionTabItem = ({ Id, Title, Note, linkedFile, CreatedDateTime, DocsFile }) => {
  const storage = getStorage();

  const handleDownload = async () => {
    fetch(linkedFile)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "myfile.png";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };
  return (
    <TableRow hover key={Id}>
      {/* Title Column */}
      <TableCell>
        <Typography variant="body2">{Title}</Typography>
      </TableCell>

      {/* Note Column */}
      <TableCell>
        <Typography variant="body2" color="textSecondary">
          {Note}
        </Typography>
      </TableCell>

      {/* Date Column */}
      <TableCell>
        <Typography variant="body2">
          {moment(CreatedDateTime).format("DD MMM YYYY")}
        </Typography>
      </TableCell>

      {/* File Column */}
      <TableCell>

        <Button
          sx={{
            borderRadius: '20px', // Rounded but not too much
            bgcolor: '#f5f5f5',
            color: '#414141',
            fontSize: '14px', // 14px as in the default theme
            textTransform: 'capitalize',
            '&:hover': {
              background: "#f5f5f5"
            }
          }} className="d-flex justify-content-center align-items-center gap-2 rounded-3 px-3 "
          onClick={() => { window.open(DocsFile) }}
        >
          <Visibility />
          View
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default PrescriptionTabItem;
