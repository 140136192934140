import React, { useEffect, useRef, useState } from "react";
import { Box, List, ListItem, ListItemText, Avatar, Typography, CircularProgress, Paper, TextField, Button } from "@mui/material";
import { useAuth } from "../../auth";
import { useLocation, useNavigate } from "react-router-dom";
import isUserLoggedIn from "./helpers/redirect";
import { NoDataSvg } from "./helpers/Svg";

function ExpertChatDB() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [clients, setClients] = useState([]);
  const [chats, setChats] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [identifier, setIdentifier] = useState(0);
  const chatRef = useRef();
  // Check if the user is logged in
  useEffect(() => {
    const isLoggedIn = isUserLoggedIn();
    if (!isLoggedIn) {
      window.location.href = `https://www${process.env.REACT_APP_CLIENT_DOMAIN}/`
    }
  }, [location, navigate]);

  // Fetch the list of clients
  const fetchClients = async () => {
    try {
      const response = await fetch(`https://integrations.uwc.world/api/offline_chat/get_all_chats_for_expert/${currentUser?.UserId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data?.code === 200) {
          setClients(data.data);
          setIsLoading(false)// Set clients from the response
        }
      } else {
        throw new Error("Failed to fetch clients");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClientChat = async (identifier) => {
    try {
      const response = await fetch(`https://integrations.uwc.world/api/offline_chat/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          identifier: identifier
        })
      });

      if (response.ok) {
        const data = await response.json();
        if (data?.code === 200) {
          if (data?.data?.length > 0) {
            setChats(data?.data)
          }
        }
      } else {
        throw new Error("Failed to fetch clients");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendClientChat = async () => {
    try {
      const response = await fetch(`https://integrations.uwc.world/api/offline_chat/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to_id: selectedClient?.to_id,
          from_id: currentUser?.UserId,
          message: newMessage,
          appointment_id: ""
        })
      });

      if (response.ok) {
        const data = await response.json();
        if (data?.code === 200) {
          setNewMessage("");
          fetchClientChat(identifier);
        }
      } else {
        throw new Error("Failed to fetch clients");
      }
    } catch (error) {
      console.error(error);
    }
  };



  useEffect(() => {
    if (currentUser?.UserId) {
      fetchClients();
    }
  }, [currentUser?.UserId]);



  // Handle selecting a client to load their chat
  const handleClientSelect = (client) => {
    setSelectedClient(client);

    if (client?.messages?.[0]?.identifier) {
      fetchClientChat(client?.messages?.[0]?.identifier)
      setIdentifier(client?.messages?.[0]?.identifier)
    } else {
      setChats(client?.messages)
    }
  };

  useEffect(() => {
    let intervalId;

    if (identifier) {
      intervalId = setInterval(() => {
        fetchClientChat(identifier);
      }, 1000 * 60); // Every 1 minutes
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Clear the interval
      }
    };
  }, [identifier]);

  useEffect(() => {
    scrollToBottom();
  }, [chats]);


  // Handle sending a new message (not implemented, but placeholder for future)
  const handleSendMessage = () => {
    if (newMessage.trim()) {
      sendClientChat()

    }
  };
  const scrollToBottom = () => {
    chatRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <Box display="flex" flexDirection="row" height="85vh" bgcolor="#f5f5f5">
      {/* Left side: Client list */}
      <Box width="30%" height={"100%"} borderRight="1px solid #ddd" overflow="auto" bgcolor="#fff" boxShadow={2}>
        <Typography variant="h6" padding={2} borderBottom={1} align="start" sx={{ color: "var(--primary-base-color)", fontWeight: "bold" }}>
          Clients
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : null}
        {!isLoading && clients?.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography>No Clients Yet</Typography>
          </Box>
        ) : (
          <List sx={{ padding: 0 }}>
            {clients?.map((client) => (
              <ListItem
                button
                key={client?.to_id}
                onClick={() => handleClientSelect(client)}
                sx={{
                  '&:hover': {
                    backgroundColor: 'var(--primary-base-opacity30)',
                  },
                  backgroundColor: selectedClient === client ? 'var(--primary-base-opacity50)' : 'transparent',
                  color: selectedClient === client ? 'white' : 'black',
                }}
              >
                <Avatar
                  src={client?.user_details?.image}
                  alt={client?.user_details?.name}
                  sx={{ marginRight: 2 }}
                />
                <ListItemText
                  sx={{ color: selectedClient === client ? 'white' : 'black', }}
                  primary={
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {client?.user_details?.name}
                    </Typography>
                  }
                  secondary={client?.user_details?.email}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>

      {/* Right side: Chat window */}
      <Box width="70%" paddingX={2} paddingTop={2} display="flex" flexDirection="column" bgcolor="#f9f9f9">
        {selectedClient ? (
          <>
            <Typography
              variant="h6"
              gutterBottom
              sx={{ fontWeight: 'bold', color: 'var(--primary-base-color)', paddingBottom: 2, borderBottom: '1px solid #ddd' }}
            >
              {selectedClient.user_details.name}
            </Typography>

            {/* Chat messages */}
            <Paper
              elevation={3}
              sx={{
                flex: 1,
                padding: 3,
                overflowY: "auto",
                borderRadius: 2,
                backgroundColor: "#fff",
                boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
              }}
            >
              {chats?.length === 0 ? (
                <div className="NoDataAvailable">
                  {NoDataSvg}
                  <Typography>No messages Yet</Typography>
                </div>
              ) : (
                chats.reduce((acc, chat, index, arr) => {
                  const currentChatDate = new Date(chat?.timestamp).toLocaleDateString();
                  const previousChatDate = index > 0 ? new Date(arr[index - 1]?.timestamp).toLocaleDateString() : null;

                  if (currentChatDate !== previousChatDate) {
                    acc.push(
                      <Typography
                        key={`date-${index}`}
                        variant="caption"
                        sx={{
                          textAlign: "center",
                          display: "block",
                          marginBottom: 1,
                          color: "gray"
                        }}
                      >
                        {currentChatDate}
                      </Typography>
                    );
                  }

                  acc.push(
                    <Box key={index} mb={2} display="flex" flexDirection="column">
                      <Typography
                        variant="body2"
                        sx={{
                          color: chat?.from_id === currentUser?.UserId ? "var(--primary-base-color)" : "textSecondary",
                          fontWeight: chat?.from_id === currentUser?.UserId ? '600' : '500',
                          backgroundColor: chat?.from_id === currentUser?.UserId ? "var(--primary-accent-color)" : "#f1f1f1",
                          padding: 1,
                          borderRadius: 1,
                          maxWidth: "70%",
                          alignSelf: chat.from_id === currentUser?.UserId ? 'flex-end' : 'flex-start',
                        }}
                      >
                        {chat?.message}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        align={chat?.from_id === currentUser?.UserId ? 'right' : 'left'}
                      >
                        {new Date(chat?.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </Typography>
                    </Box>
                  );

                  return acc;
                }, [])
              )}
              <div ref={chatRef}></div>
            </Paper>


            {/* Input for new message */}
            <Box display="flex" gap={2} alignItems={"center"} mt={2}>
              <TextField
                fullWidth
                placeholder="Type your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                sx={{ backgroundColor: "#fff", borderRadius: 1 }}
                onKeyDown={(e) => {

                  if (e && e.code === "Enter" && !e.shiftKey) {
                    e.preventDefault();

                    handleSendMessage();

                  }
                }}
              />
              <Button
                onClick={handleSendMessage}
                variant="contained"
                className="PrimaryCTAExp"

                disabled={newMessage.trim() === ""}
              >
                Send
              </Button>
            </Box>
          </>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
            <div className="NoDataAvailable">
              {NoDataSvg}
              <Typography>Select a client to start chat</Typography>
            </div>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ExpertChatDB;
