import React from "react";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import TodayIcon from "@mui/icons-material/Today";
import { RateReview, Today } from "@mui/icons-material";

const NotificationRowCard = ({
  image,
  title,
  clientID,
  appointmentID,
  type,
  description,
  day,
  time,
  clientType,
  session,
  joinNow,
}) => {
  // console.log("title", title, "joinNow", joinNow);

  const navigate = useNavigate();

  const onProfile = () => {
    navigate(`/expert/clients?id=${clientID}`);
  };

  return (
    <div className="NotificationRowCard">
      {image == false && type !== "review" ? (
        <div className="newAppointmentIcon">
          <Today />
        </div>
      ) : type == "review" ? (
        <div className="newAppointmentIcon">
          <RateReview />
        </div>
      ) : (
        <img
          src={image}
          width={40}
          height={40}
          style={{ borderRadius: "50%", objectFit: "cover" }}
        />
      )}

      <div className="NotificationRowCard--Content">
        <p className="header">{title}</p>

        {description && <p className="review">{description}.</p>}

        {type == "appointment" && (
          <>
            <div className="newAppointment--details">
              <p>
                {day} {time}
              </p>
              |<p>{clientType}</p>|<p>{session}</p>
            </div>

            <div className="Content--Actions">
              <button className="ProfileCTA" onClick={onProfile}>
                Client Profile
              </button>
              {joinNow && (
                <Link to="/expert/appointments">
                  <button className="JoinCTA">Join Now</button>
                </Link>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationRowCard;
