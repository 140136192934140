import { useState } from "react";
import moment from "moment";
import { TableCell, TableRow, Typography } from "@mui/material";

const NoteTabItem = ({ id, create_date, name, content }) => {
  return (
    <TableRow hover key={id}>
      {/* Date Column */}
      <TableCell>
        <Typography variant="body2">
          {moment(create_date).format("DD MMM YYYY")}
        </Typography>
      </TableCell>

      {/* Name Column */}
      <TableCell>
        <Typography variant="body2">{name}</Typography>
      </TableCell>

      {/* Content Column */}
      <TableCell>
        <Typography variant="body2" color="textSecondary">
          {content}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default NoteTabItem;
