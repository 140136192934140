import { useState, useEffect, forwardRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AirplaneTicketOutlinedIcon from "@mui/icons-material/AirplaneTicketOutlined";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Switch from "@mui/material/Switch";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Grid from "@mui/material/Grid";
import moment from "moment";
import dayjs from "dayjs";
//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { useAuth } from "../../../auth";

import { getToken } from "../APIS/clientAPIS";

import jwtDecode from "jwt-decode";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EnableHolidayModeModal = ({ onHolidayMode }) => {
  const [open, setOpen] = useState(false);
  const [client, setClient] = useState("");
  const [checked, setChecked] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().format("DD/MM/YYYY"));
  const [toDate, setToDate] = useState(dayjs().format("DD/MM/YYYY"));
  const [slotsData, setSlotsData] = useState([]);
  const [holidayList, setHolidayList] = useState({
    startDate: '',
    endDate: ''
  })

  const [holidayMessage, setHolidayMessage] = useState("");

  const [bearerToken, setBearerToken] = useState(sessionStorage.getItem("token"));

  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const { currentUser, userType, logout } = useAuth();
  const userID = JSON.parse(localStorage.getItem("profile")).UserId;



  const getSlotsData = async () => {
    const payload = {
      user_id: userID,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/slots`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const { slots } = await postResponse.data;
      const { holidays } = await postResponse.data;
      //console.log("data", holidays[0])

      setSlotsData(slots);
      if (holidays.length > 0) {
        setHolidayList({
          startDate: dayjs(holidays[0].start_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
          endDate: dayjs(holidays[0].end_date, "YYYY-MM-DD").format("DD/MM/YYYY")
        })
      }

    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Get Slots and Holidays List
  useEffect(() => {
    return () => {
      getSlotsData();
    }

  }, []);

  const handleClientSelect = (event) => {
    setClient(event.target.value);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const handlerRedirectToAppointments = () => {
    navigate("/expert/appointments");
  };

  const handleFromDateChange = (date) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setFromDate(formattedDate);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleToDateChange = (date) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setToDate(formattedDate);
  };

  const countSlotsBetweenDates = (slots, startDate, endDate) => {

    const start = dayjs(startDate, "DD/MM/YYYY").format("YYYY-MM-DD");
    const end = dayjs(endDate, "DD/MM/YYYY").format("YYYY-MM-DD");

    let count = 0;
    slots.forEach((slot) => {
      const slotDate = dayjs(slot.date);
      if (slotDate.isBetween(start, end, null, "[]")) {
        count++;
      }
    });

    return count;
  };

  const numberOfSlots = countSlotsBetweenDates(slotsData, fromDate, toDate);
  //console.log("numberOfSlots", numberOfSlots)

  const handleHolidayMessage = (e) => {
    setHolidayMessage(e.target.value);
  };


  const handleHolidayMode = async (retryCount = 0) => {
    const MAX_RETRY = 3; // Maximum number of retries

    const startDate = dayjs(fromDate, "DD/MM/YYYY").format("YYYY/MM/DD");
    const endDate = dayjs(toDate, "DD/MM/YYYY").format("YYYY/MM/DD")

    let sessionBearerToken = sessionStorage.getItem("token");
    const payload = {
      expert_id: userID,
      from_date: startDate,
      to_date: endDate,
    };

    const holidayModeHeaders = {
      "Content-Type": "application/json",
      "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
      Authorization: `Bearer ${sessionBearerToken}`,
    };

    //console.log("payload", payload);

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/holiday`,
        {
          method: "POST",
          headers: holidayModeHeaders,
          body: JSON.stringify(payload)
        }
      );
      const postResponse = await response.json();

      const reponseCode = await postResponse.code;

      if (reponseCode === 200) {
        handleClose();
        setSnackbarMessage("Holiday Mode activated!");
        setOpenSnackbar(true);
      } else if (reponseCode == 401 && retryCount < MAX_RETRY) {
        getToken(currentUser?.Emailid).then(res => {
          setTimeout(() => handleHolidayMode(retryCount + 1), 0)
        })

      }


    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Button className="HolidayMode PrimaryCTAExp" onClick={handleClickOpen}>
        <AirplaneTicketOutlinedIcon />
        Holiday Mode
      </Button>

      <Dialog
        className="HolidayMode--Dialog"
        open={open}
        onClose={handleClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <DialogTitle sx={{ paddingBottom: "5px" }}>Holiday Mode</DialogTitle>
        <p
          style={{
            padding: "0 24px",
            color: "#656565",
            fontSize: "16px",
            lineHeight: "130%",
          }}
        >
          Please utilize the Holiday Mode feature to block your calendar. During
          this period, any scheduled slots between the specified dates that
          remain unbooked will not be visible to clients.
        </p>
        <DialogContent className="Dialog--Content">
          <div className="d-flex align-items-center gap-3 mt-0">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD/MM/YYYY"
                value={dayjs(fromDate, "DD/MM/YYYY")}
                onChange={handleFromDateChange}
                label="From Date"
                disablePast={true}
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD/MM/YYYY"
                value={dayjs(toDate, "DD/MM/YYYY")}
                onChange={handleToDateChange}
                disablePast={true}
                label="To Date"
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
          </div>

          <div>
            {numberOfSlots > 0 && (
              <>
                <div className="d-flex align-items-center gap-2 mt-3">
                  <WarningRoundedIcon sx={{ color: "#D2293D" }} />
                  <p style={{ fontSize: "16px" }}>
                    You have {numberOfSlots} Appointment between selected dates
                  </p>
                </div>
                <Button
                  sx={{
                    borderRadius: "30px",
                    textTransform: "capitalize",
                    color: "var(--primary-base-color)",
                    backgroundColor: "var(--primary-base-opacity30)",
                    marginBottom: "10px",
                    "&:hover": {
                      color: "white",
                      backgroundColor: "var(--primary-base-color)",
                    }
                  }}
                  className="mt-3 "
                  variant="contained"
                  disableElevation
                  onClick={handlerRedirectToAppointments}
                >
                  Go to Appointments
                </Button>
              </>
            )}
          </div>

          {/* <TextField
            className="mt-3"
            sx={{ width: "100%" }}
            multiline
            maxRows={2}
            id="optionalMessage"
            label="Message for Already booked Appointment(Optional)"
            value={holidayMessage}
            onChange={handleHolidayMessage}
          /> */}
        </DialogContent>
        <DialogActions className="d-flex align-items-center pb-3 mb-2">
          <Button
            onClick={handleClose}
            variant="text"
            sx={{
              color: "#D2293D",
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={numberOfSlots > 0 ? true : false}
            className="ConfirmBooking PrimaryCTAExp"
            onClick={handleHolidayMode}
            variant="contained"
            disableElevation
          >
            Turn on Holiday Mode
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EnableHolidayModeModal;
